import type Keycloak from "keycloak-js";

export class DeleteAccount {
  private deleteButton: HTMLButtonElement;

  private keycloak: Keycloak;

  constructor(keycloak: Keycloak) {
    this.keycloak = keycloak;
    this.deleteButton = document.getElementById(
      "delete-account-button",
    ) as HTMLButtonElement;
    this.deleteButton.addEventListener("click", () => this.onDelete());
  }

  async onDelete() {
    await this.keycloak.login({
      action: "delete-via-cdrs",
    });
  }
}
